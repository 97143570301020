@import "../styles/layout.scss";
@import "../styles//typography.scss";
@import "https://unpkg.com/transition-style";

.home-header-style {
  background: transparent;
  top: 0;
  color: white;
  z-index: 2;
}

.header-style {
  background: white;
  color: black;
}

.home-active-tab {
  color: white;
  opacity: 1;
}

.home-inactive-tab {
  color: white;
  opacity: 100;
}

.other-active-tab {
  color: $font-orange;
  opacity: 100;
}
f .home-menu-button {
  color: white;
}

.other-tab-menu-button {
  color: black;
}

@keyframes circle-in-top-right {
  from {
    clip-path: var(--circle-center-center-out);
  }
  to {
    clip-path: var(--circle-top-right-in);
  }
}

[transition-style="in:circle:top-right"] {
  animation-name: circle-in-top-right;
}

.mobile-menu {
  background-color: $background-dark-blue;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

.animate-page-header {
  animation: slideDown 0.35s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

#list {
  margin: 2em auto;
  padding: 0;
  max-width: 600px;
  list-style: none;
  height: 15em;
}

#list li {
  opacity: 0;
  animation: fadeInList 1s ease-in both;
}

#list li:nth-child(2) {
  animation-delay: 0.1s;
}
#list li:nth-child(3) {
  animation-delay: 0.2s;
}
#list li:nth-child(4) {
  animation-delay: 0.3s;
}
#list li:nth-child(5) {
  animation-delay: 0.4s;
}
#list li:nth-child(6) {
  animation-delay: 0.5s;
}

@keyframes fadeInList {
  from {
    opacity: 0;
    transform: translate3d(0, -20%, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.solution-mobile-dropdown {
  animation: fadeInList 1s ease-in both;
}

@keyframes in-circle-swoop {
  from {
    clip-path: var(--circle-top-right-out);
  }
  to {
    clip-path: var(--circle-bottom-right-in);
  }
}

[transition-style="in:custom:circle-swoop"] {
  --transition__duration: 0.8s;
  animation-name: in-circle-swoop;
}

.underline-animation {
  padding-bottom: 5px;
  margin-left: 1rem;
  width: 80%;
  align-items: center;
  background-image: linear-gradient(#fd8c00 0 0);
  background-position: 0 100%; /*OR bottom left*/
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.4s, background-position 0s 0.4s; /*change after the size immediately*/
}

.underline-animation:hover {
  background-position: 0 100%; /*OR bottom right*/
  background-size: 100% 2px;
  margin-left: 1.2rem;
}

@media screen and (min-width: 1024px) {
  .stop-scrolling {
    height: 100%;
    overflow: auto;
  }
}
