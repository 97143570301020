@import "../../styles/layout.scss";
@import "../../styles//typography.scss";

.feature_location_title {
  margin-top: -75px;
  height: 76px;
  padding: 15px;
  padding-left: 150px;
  background-color: rgba(37, 42, 56, 0.6);
  z-index: 5;
  position: relative;
  color: white;
  font-size: 30px;
  @media screen and (max-width: 640px) {
    margin-top: -25px;
    height: 25px;
    padding: 0;
    padding-left: 50px;
    font-size: 15px;
  }
}

.gray-background-content {
  background-color: $background-light-primary-gray;
}

.link-text-style {
  color: $font-dark-blue;
}

.secondary-gray-background {
  background-color: $background-light-secondary-gray;
}
