@font-face {
  font-family: "Avenir Light";
  src: url("../../public/fonts/Avenir-Light.ttf");
}

@font-face {
    font-family: "Avenir Medium";
    src: url("../../public/fonts/Avenir-Medium.ttf");
}

@font-face {
  font-family: "Avenir Book";
  src: url("../../public/fonts/Avenir-Book.otf");
}

@font-face {
  font-family: "Gotham Bold";
  src: url("../../public/fonts/Gotham-Bold.otf");
}

@font-face {
  font-family: "Gotham Light";
  src: url("../../public/fonts/Gotham-Light.otf");
}
