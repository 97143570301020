@import "../../styles/layout.scss";

//Home Top Section CSS
.home-position {
  position: relative;
  top: 0;
}
.top-home-banner {
  background-image: 'url("/images/background.png")';
}
.home-top-banner-content {
  position: absolute;
  top: 50px;
}

.home-top-banner-grid {
  display: grid;
  grid-template-columns: 1.2fr 0.8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: ". .";
}

//Home Content CSS
.home-content-title {
  background-color: $background-light-primary-gray;
  text-align: center;
}

//Carousel Styling
.slick-slide {
  padding-right: 5px;
}
